import { Socket } from 'socket.io-client';
import { useSocketIoFactory } from './useSocketIoFactory';
import { Nullable } from '@package/interfaces';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { UserRankUpdatePayload } from '@/services/dto/ranks/user-rank-update-payload';
import { RankStatus } from '@/models/rank/rankStatus';
import { RankQueryQueys } from '@/api/rank-status/queryKeys';
import { getStore } from '@/lib/store';
import { setLevelUp } from '@/lib/store/casino/actions';
import { UserRewardsQueryKeys } from '@/api/user-rewards/queryKeys';

export default function useRanksSocket() {
  const socket: Nullable<Socket> = useSocketIoFactory({
    gateway: 'ranks',
    isProtected: true,
  });
  const queryClient: QueryClient = useQueryClient();

  const onConnect: VoidFunction = useCallback(() => {
    if (!socket) {
      return;
    }

    socket.emit('join');
  }, [socket]);

  const onUpdate: (payload: UserRankUpdatePayload) => void = useCallback(
    ({ levelUp, rankStatus }: UserRankUpdatePayload) => {
      queryClient.setQueryData<RankStatus>([RankQueryQueys.ByUser], rankStatus);
      queryClient.invalidateQueries({
        queryKey: [UserRewardsQueryKeys.Primary],
      });

      if (levelUp) {
        getStore().dispatch(setLevelUp(rankStatus.rank));
      }
    },
    [queryClient],
  );

  function setServerOnEvents() {
    if (!socket) return;

    socket.on('connect', onConnect);
    socket.on('update', onUpdate);
  }

  useEffect(setServerOnEvents, [socket, onConnect, onUpdate]);
}
