import OAuthButton from './Button';
import styles from './styles.module.scss';
import { OAuthProps } from './props';
import { useModal } from '@package/hooks';
import { ModalsKey } from '@/enums/modalsKey';

export default function OAuthRow({ direction = 'row' }: OAuthProps) {
  const { isOpen: isRegisterModalOpen } = useModal(ModalsKey.Register);

  return (
    <div className={`${styles.container} `}>
      <div
        className={`${styles.container__grid} ${direction == 'column' ? styles['container__grid--column'] : ''}`}
      >
        <OAuthButton
          to={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_URL as string}
          image={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/google-icon.svg`}
          label={isRegisterModalOpen ? 'google_register' : 'google_login'}
        />

        <OAuthButton
          to={process.env.NEXT_PUBLIC_DISCORD_OAUTH_URL as string}
          image={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/discord-icon.svg`}
          label={isRegisterModalOpen ? 'discord_register' : 'discord_login'}
        />
      </div>
    </div>
  );
}
