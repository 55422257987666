import { useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { FormGroup, Input } from '@starsoft/common/components';
import { AuthLoginRequestDto } from '@/services/dto/auth/auth-login-request.dto';

export default function LoginPasswordContent() {
  const { register } = useFormContext<AuthLoginRequestDto>();
  const { t } = useTranslation('login');

  return (
    <FormGroup label={t('password_placeholder')}>
      <Input
        type="password"
        placeholder={t('password_placeholder')}
        name="password"
        register={register}
        autoFocus={false}
        focusOnMount={false}
        registerOptions={{
          required: true,
        }}
      />
    </FormGroup>
  );
}
