import { combineReducers } from 'redux';
import themeReducer from './theme/reducer';
import authReducer from './auth/reducer';
import withdrawBonusReducer from './withdraw-bonus/reducer';
import usersNetworkReducer from './users-network/reducer';
import crashReducer from './crash/reducer';
import doubleReducer from './double/reducer';
import mineReducer from './mines/reducer';
import notificationsReducer from './notifications/reducer';
import plinkoReducer from './plinko/reducer';
import diceReducer from './dice/reducer';
import sideBarReducer from './side-bar/reducer';
import casinoReducer from './casino/reducer';
import towerReducer from './tower/reducer';
import coinflipReducer from './coinflip/reducer';
import limboReducer from './limbo/reducer';
import slideReducer from './slide/reducer';
import gamesAudioReducer from './games-audio/reducer';
import walletReducer from './wallet/reducer';
import gamePreferencesReducer from './games-preferences/reducer';
import investmentReducer from './investment/reducer';
import datePickerReducer from './date-picker/reducer';
import liveChatReducer from './live-chat/reducer';
import giphyReducer from './giphy/reducer';
import supportReducer from './support/reducer';
import particleReducer from './particle/reducer';
import fortuneDoubleReducer from './fortune-double/reducer';
import depositReducer from './deposit/reducer';
import luckySpinReducer from './lucky-spin/reducer';
import missionUncrossableReducer from './mission-uncrossable/reducer';
import affiliatesReducer from './affiliates/reducer';
import tournamentReducer from './tournaments/reducer';
import rollPointReducer from './roll-point/reducer';

const rootReducer = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  usersNetwork: usersNetworkReducer,
  crash: crashReducer,
  double: doubleReducer,
  withdrawBonus: withdrawBonusReducer,
  mines: mineReducer,
  notifications: notificationsReducer,
  plinko: plinkoReducer,
  dice: diceReducer,
  sideBar: sideBarReducer,
  casino: casinoReducer,
  tower: towerReducer,
  coinflip: coinflipReducer,
  limbo: limboReducer,
  slide: slideReducer,
  gamesAudio: gamesAudioReducer,
  wallet: walletReducer,
  gamePreferences: gamePreferencesReducer,
  investment: investmentReducer,
  datePicker: datePickerReducer,
  liveChat: liveChatReducer,
  giphy: giphyReducer,
  support: supportReducer,
  particle: particleReducer,
  fortuneDouble: fortuneDoubleReducer,
  deposit: depositReducer,
  luckSpin: luckySpinReducer,
  missionUncrossable: missionUncrossableReducer,
  affiliates: affiliatesReducer,
  tournaments: tournamentReducer,
  rollPoint: rollPointReducer,
});

export default rootReducer;
