import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { useUpdateWalletAndAccounts } from '../useUpdateWalletAndAccounts';
import { useSocketIoFactory } from './useSocketIoFactory';
import { AccountUpdateDto } from '@/services/dto/accounts/account-update.dto';
import { Wallet } from '@/models/wallet';
import { Account } from '@package/models';
import { WalletsQueryKeys } from '@/api/wallets/queryKeys';
import { BetQueryKeys } from '@/api/bet/queryKeys';

export default function useAccountsSocket() {
  const socket: Socket | null = useSocketIoFactory({
    gateway: 'accounts',
    isProtected: true,
  });
  const onUpdate: (data: AccountUpdateDto) => void =
    useUpdateWalletAndAccounts();
  const queryClient: QueryClient = useQueryClient();

  const updateWallet = useCallback(
    (wallet: Wallet | undefined, account: Account): Wallet | undefined => {
      if (!wallet) {
        queryClient.invalidateQueries({
          queryKey: [WalletsQueryKeys.Me],
        });
        return wallet;
      }

      return {
        ...wallet,
        currentAccount: account,
      };
    },
    [queryClient],
  );

  const handleUpdateCurrentAccount = useCallback(
    (account: Account) => {
      queryClient.setQueriesData<Wallet>(
        { queryKey: [WalletsQueryKeys.Me] },
        wallet => updateWallet(wallet, account),
      );
      queryClient.invalidateQueries({ queryKey: [BetQueryKeys.BetbyToken] });
    },
    [queryClient, updateWallet],
  );

  const onUpdateCurrentAccount = useCallback(
    ({ account, delay }: AccountUpdateDto) => {
      if (!!delay) {
        setTimeout(() => handleUpdateCurrentAccount(account), delay);
        return;
      }

      handleUpdateCurrentAccount(account);
    },
    [handleUpdateCurrentAccount],
  );
  const onConnect = useCallback(() => {
    socket?.emit('join');
  }, [socket]);

  function setServerOnEvents() {
    if (!socket) return;

    socket.on('connect', onConnect);
    socket.on('update', onUpdate);
    socket.on('update-current-account', onUpdateCurrentAccount);
  }

  useEffect(setServerOnEvents, [
    socket,
    onUpdate,
    onConnect,
    queryClient,
    onUpdateCurrentAccount,
  ]);
}
