import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { ProfileRankCardProps } from '../props';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';
import { ComponentTypeId } from '@package/src/models/setting/layout/component/type/types.enum';
import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';
import RankLabel from '@/components/RankLabel';
import RankCardProgress from '../Progress';

export default function ProfileRankCardSkeleton({
  isRedirect,
  isTransparent,
}: Pick<ProfileRankCardProps, 'isRedirect' | 'isTransparent'>): JSX.Element {
  const { components } = useResolvedTheme();
  const version: SettingLayoutComponentId =
    components.get(ComponentTypeId.ProfileRankCards) ??
    SettingLayoutComponentId.ProfileRankCardV1;

  return (
    <div
      className={`${styles.container} ${isTransparent ? styles['container--transparent'] : ''} ${styles['container--loading']}`}
    >
      <div className={styles.container__row}>
        <div
          className={`${skeleton.image} ${version === SettingLayoutComponentId.ProfileRankCardV1 ? skeleton['image--large'] : ''}`}
        />

        <div className={styles.container__column}>
          <div className={skeleton.name} />

          <RankLabel rank={null} loading />

          {version === SettingLayoutComponentId.ProfileRankCardV2 && (
            <div
              className={`${styles.container__row} ${styles['container__row--small']}`}
            >
              <RankCardProgress rankStatus={null} isLoading />
              <div className={skeleton.progress} />
            </div>
          )}
        </div>
      </div>

      {isRedirect && (
        <i
          className={`fa-solid fa-chevron-right ${styles['container__chevron']}`}
        />
      )}
    </div>
  );
}
