import SidebarContent from './Content';
import SidebarHeader from './Header';
import BonusButton from './BonusButton';
import RewardsSection from './Content/NotCollapsed/RewardsSection';

import styles from './styles.module.scss';
import { useShowNavigation } from '@/hooks/useShowNavigation';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';

function SideBar() {
  const { isV2LayoutVariant, isCollapsed } = useLayoutSettings();

  const showNaviation = useShowNavigation();

  return (
    <div
      className={`${styles.container} ${!showNaviation ? styles['container--hidden'] : ''}`}
    >
      <aside
        className={`${styles.container__aside} ${isCollapsed ? styles['container__aside--collapsed'] : ''}`}
      >
        <div className={styles['container__desktop_only']}>
          <SidebarHeader />
        </div>

        <section
          className={`${styles.container__content} ${isCollapsed ? styles['container__content--collapsed'] : ''}`}
        >
          {!isCollapsed && (
            <>
              <BonusButton />
              <div className={styles.container__spacer} />
              {!isV2LayoutVariant && <RewardsSection />}
            </>
          )}

          <SidebarContent />
        </section>
      </aside>
    </div>
  );
}

export default SideBar;
