import { RootState } from '@/lib/store';
import { Nullable } from '@package/interfaces';
import { useSelector } from 'react-redux';
import { UseRollPointColumnsGenerationProps } from './props';
import { useCallback } from 'react';

export function useRollPointColumnsGeneration({
  column,
  rotations = 5,
}: UseRollPointColumnsGenerationProps) {
  const result: Nullable<number[]> = useSelector(
    (state: RootState) => state.rollPoint.result,
  );
  const columnResult = result?.[column];
  const columns = rotations * 10;
  const resultPosition = columns - 10 + columnResult;

  const getLabel = useCallback((position: number) => {
    return position % 10;
  }, []);

  return {
    columns,
    getLabel,
    resultPosition,
  };
}
