import { ModalsKey } from '@/enums/modalsKey';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { usePathname } from '../usePathname';
import { useSearchParams } from '@package/hooks';

export function useModalNavigation() {
  const searchParams: URLSearchParams = useSearchParams();
  const { push } = useRouter();
  const pathname: string = usePathname();

  const getModalLink = useCallback(
    (modal: ModalsKey, params?: string): string => {
      const currentQueryParams: string = searchParams.toString()
        ? `?${searchParams.toString()}`
        : '';
      return `${pathname}${params ? params : currentQueryParams}#${modal}`;
    },
    [pathname, searchParams],
  );

  const navigateToModal = useCallback(
    (modal: ModalsKey, params?: string) => {
      const url: string = getModalLink(modal, params);
      push(url, undefined, {
        scroll: false,
        shallow: false,
      });
    },
    [push, getModalLink],
  );

  return {
    getModalLink,
    navigateToModal,
  };
}
