import { ModalsKey } from '@/enums/modalsKey';
import AuthModalLoginContent from './Login';
import GenerateRecoveryPasswordToken from './RecoverPassword/Content/GenerateRecoveryPasswordToken';
import ResetPassword from './RecoverPassword/Content/ResetPassword';
import AuthModalRegisterContent from './Register';

export const authModalOptions: Partial<
  Record<
    ModalsKey,
    { key: string; title: string; component: (id?: number) => JSX.Element }
  >
> = {
  [ModalsKey.Login]: {
    key: ModalsKey.Login,
    title: 'app_login',
    component: (id?: number) => <AuthModalLoginContent id={id} />,
  },
  [ModalsKey.Register]: {
    key: ModalsKey.Register,
    title: 'app_register',
    component: (id?: number) => <AuthModalRegisterContent id={id} />,
  },
  [ModalsKey.RecoverPassword]: {
    key: ModalsKey.RecoverPassword,
    title: 'recover_password_title',
    component: () => <GenerateRecoveryPasswordToken />,
  },
  [ModalsKey.RecoverPasswordReset]: {
    key: ModalsKey.RecoverPasswordReset,
    title: 'recover_password_reset_title',
    component: () => <ResetPassword />,
  },
};
