import RollPointGameContent from './Content';
import RollPointGameLayout from './Layout';

export default function RollPointGame() {
  return (
    <RollPointGameLayout>
      <RollPointGameContent />
    </RollPointGameLayout>
  );
}
