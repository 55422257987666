import AuthModalContentRegisterSteps from './Content/Steps';
import RegisterLayout from './Layout';

export default function AuthModalRegisterContent({ id }: { id: number }) {
  return (
    <RegisterLayout id={id}>
      <AuthModalContentRegisterSteps />
    </RegisterLayout>
  );
}
