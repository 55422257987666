import { useModal } from '@package/hooks';
import { ModalsKey } from '@/enums/modalsKey';
import { useUserAccount } from '@/api/user';
import ProfileDrawerContent from './Content';
import { PageDrawer } from '@package/components';
import useTranslation from 'next-translate/useTranslation';

export default function ProfileDrawer() {
  const { t } = useTranslation('common');
  const { isOpen, onClose } = useModal(ModalsKey.ProfileDetails);
  const { isAuthorized } = useUserAccount();

  function handleClose() {
    onClose();
  }

  return (
    <PageDrawer
      isOpen={isOpen && isAuthorized}
      onClose={handleClose}
      title={t('tab_profile')}
    >
      <ProfileDrawerContent />
    </PageDrawer>
  );
}
