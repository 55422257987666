import { AuthModalLayoutProps } from './props';
import { setCookie } from 'cookies-next';
import { CookiesKey } from '@/enums/cookiesKey';
import { useEffect } from 'react';
import { usePathname } from '@/hooks/usePathname';
import { Modal } from '@starsoft/common/components';

import dynamic from 'next/dynamic';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';

const LayoutV1 = dynamic(() => import('./v1'), { ssr: true });
const LayoutV2 = dynamic(() => import('./v2'), { ssr: true });
const LayoutV3 = dynamic(() => import('./v3'), { ssr: true });

export default function AuthModalLayout(props?: AuthModalLayoutProps) {
  const { open, id = 1, closeModalOnClickOutside } = props ?? {};
  const pathname: string = usePathname();

  function persistClientRouter() {
    if (!open) {
      return;
    }

    setCookie(CookiesKey.ClientRouter, pathname);
  }

  useEffect(persistClientRouter, [pathname, open]);

  function renderLayout() {
    switch (id) {
      case SettingLayoutComponentId.DefaultLogin:
      default:
        return <LayoutV1 {...props} />;
      case SettingLayoutComponentId.MinimalLogin:
        return <LayoutV2 {...props} />;
      case SettingLayoutComponentId.LoginV3:
        return <LayoutV3 {...props} />;
    }
  }

  return (
    <Modal open={open} onClose={closeModalOnClickOutside}>
      {renderLayout()}
    </Modal>
  );
}
