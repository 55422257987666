import { FormProvider, useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { NotificationsService } from '@/services/NotificationsService';
import { ModalsKey } from '@/enums/modalsKey';
import Link from '@/components/core/Link';
import { useLogin } from '@/api/iam';
import styles from './styles.module.scss';
import { Animation } from '@starsoft/common/components';
import { Button } from '@starsoft/common/components';
import { AnimatePresence } from 'framer-motion';
import { AuthLoginRequestDto } from '@/services/dto/auth/auth-login-request.dto';
import { PropsWithChildren, useEffect } from 'react';
import { AuthService } from '@/services/AuthService';
import Requires2faContent from './Requires2fa';
import useAuthSearchParams from '@/hooks/auth/useAuthSearchParams';
import OAuthRow from '../../OAuth';
import useAuthForm from '@/hooks/auth/useAuthForm';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';

export default function LoginLayout({
  children,
  id = 1,
}: PropsWithChildren<{ id?: number }>) {
  const methods = useForm<AuthLoginRequestDto>();
  const { handleSubmit, reset, watch } = methods;
  const { mutate, isLoading, requires2fa, onClose } = useLogin();
  const { t } = useTranslation('login');
  const { step } = useAuthSearchParams();
  const { isInvalidForm, isPasswordStep } = useAuthForm({ watch });

  const { t: tRegister } = useTranslation('register');
  const isVariant = id === SettingLayoutComponentId.LoginV3;

  const onSubmit = ({ email, password, code }: AuthLoginRequestDto) => {
    const invalid: boolean =
      email.trim().length == 0 || password.trim().length == 0;

    if (invalid) {
      NotificationsService.error({
        title: 'form_error_title',
        message: 'form_error_description',
      });
      return;
    }

    mutate({ email, password, code });
  };

  const onError = () => {
    NotificationsService.error({
      title: 'form_error_title',
      message: 'form_error_description',
    });
  };

  function handleResetOtpCode() {
    if (!requires2fa) {
      reset({ code: undefined });
    }
  }

  useEffect(handleResetOtpCode, [requires2fa, reset]);

  return (
    <Animation type="fadeInLeft" key={step}>
      <FormProvider {...methods}>
        <div
          className={`${styles.container} ${isVariant ? styles['container--variant'] : ''} `}
        >
          <form
            className={styles.container__form}
            id="login-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <AnimatePresence mode="wait">
              {requires2fa && (
                <Requires2faContent onClose={onClose} isLoading={isLoading} />
              )}
            </AnimatePresence>
            {children}

            {!isPasswordStep && (
              <Link
                className={`${styles.container__form__link} ${isVariant ? styles['container__form__link--variant'] : ''} `}
                modal={ModalsKey.RecoverPassword}
              >
                {t('forgot_password')}
              </Link>
            )}

            <Button
              type="submit"
              form="login-form"
              isloading={isLoading || AuthService.isAuthorized()}
              disabled={isInvalidForm}
            >
              {t('submit_label')}
            </Button>
          </form>
          {!isPasswordStep && (
            <>
              <span className={styles.container__binding}>
                {tRegister('register_label_binding')}
              </span>
              <OAuthRow direction={isVariant ? 'row' : 'column'} />
              {!isVariant && (
                <span className={styles.container__text}>
                  {t('register_text')}
                  <Link
                    modal={ModalsKey.Register}
                    className={styles.container__text__highlight}
                  >
                    {t('register_label')}
                  </Link>
                </span>
              )}
            </>
          )}
        </div>
      </FormProvider>
    </Animation>
  );
}
