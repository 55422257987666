import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';
import { ComponentTypeId } from '@package/src/models/setting/layout/component/type/types.enum';
import styles from './styles.module.scss';
import { RankCardProgressProps } from './props';
import { CircularProgress } from '@package/components';
import Color from 'color';
import { Nullable } from '@package/interfaces';

export default function RankCardProgress({
  isLoading,
  rankStatus,
  version,
}: RankCardProgressProps) {
  const { components } = useResolvedTheme();
  const coallescedVersion: SettingLayoutComponentId =
    version ??
    components.get(ComponentTypeId.ProfileRankCards) ??
    SettingLayoutComponentId.ProfileRankCardV1;

  function getSkeleton(): Nullable<string> {
    switch (coallescedVersion) {
      case SettingLayoutComponentId.ProfileRankCardV1:
        return null;
      case SettingLayoutComponentId.ProfileRankCardV2:
        return styles['skeleton--v2'];
      default:
        return null;
    }
  }

  const skeleton: Nullable<string> = getSkeleton();

  if (isLoading && !!skeleton) {
    return <div className={`${styles.skeleton} ${skeleton}`} />;
  }

  switch (coallescedVersion) {
    case SettingLayoutComponentId.ProfileRankCardV1:
      return (
        <CircularProgress
          progress={rankStatus?.progress}
          trailColor={rankStatus?.rank?.group?.color}
          pathColor={Color(rankStatus?.rank?.group?.color)
            .alpha(0.2)
            .toString()}
          className={styles['progress--circular']}
        />
      );
    case SettingLayoutComponentId.ProfileRankCardV2:
      return (
        <div className={styles['progress--bar']}>
          <div
            className={styles['progress--bar__progress']}
            style={{
              width: `${rankStatus?.progress ?? 0}%`,
              backgroundColor: rankStatus?.rank?.group?.color,
            }}
          />
        </div>
      );
  }
}
