export enum CookiesKey {
  Lang = '@casino/Lang',
  ClientRouter = '@casino/clientRouter',
  IsAuthorized = '@casino/IsAuthorized',
  Token = '@casino/token',
  RefreshToken = '@casino/refresh-token',
  Voucher = '@casino/voucher',
  BetbyToken = '@casino/betby-token',
  Register = '@casino/register',
  CasinoLang = '@casino/casino-lang',
  ByPass = '@casino/bypass',
}
