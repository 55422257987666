import { ComponentTypeId } from '@package/src/models/setting/layout/component/type/types.enum';
import { useResolvedTheme } from '../useResolvedTheme';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';

export function useCompomentVariant(
  typeId: ComponentTypeId,
): SettingLayoutComponentId {
  const { components } = useResolvedTheme();
  const componentVariant: SettingLayoutComponentId = components.get(typeId);

  return componentVariant;
}
