import { useLottiePlayer } from '@package/hooks';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';

export default function BonusLink() {
  const { lottieAnimationRef } = useLottiePlayer({
    path: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/lotties/gift-box.json`,
  });

  return (
    <Link href="/bonus" className={styles.container} privateRoute>
      <div ref={lottieAnimationRef} />
    </Link>
  );
}
