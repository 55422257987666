import { Nullable } from '@package/interfaces';
import {
  RollPointActions,
  SetRollPointAnimation,
  SetRollPointResult,
} from './props';

export function setIsAnimatingRollPointGame(
  payload: boolean,
): SetRollPointAnimation {
  return {
    type: RollPointActions.SetIsAnimating,
    payload,
  };
}

export function setRollPointResult(
  payload: Nullable<Array<number>>,
): SetRollPointResult {
  return {
    type: RollPointActions.SetResult,
    payload,
  };
}
