import {
  Input,
  SelectInput,
  SelectOptionWithHighlight,
} from '@package/components';
import { useFormContext } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { RegisterFormProps } from '@/types/register/props';
import { AuthModalContentRegisterEmailInputProps } from './props';
import { useEmailProviders } from '@/api/email-providers';
import React, { useCallback, useMemo } from 'react';
import { EmailProviders } from '@/models/email-providers';
import { LoginFormProps } from '@/types/login/props';

export default function AuthModalContentEmailInput({
  isValidEmail,
}: AuthModalContentRegisterEmailInputProps) {
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation('register');
  const {
    setValue,
    formState: { touchedFields },
    watch,
  } = useFormContext<RegisterFormProps | LoginFormProps>();

  const email = watch('email');
  const domain = email?.split('@')?.[1];

  const {
    emailProviders,
    isLoading,
    error,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useEmailProviders();

  const emailHasOneOfProvidersExternal = useMemo(
    () => emailProviders?.some(provider => email?.includes(provider?.domain)),
    [emailProviders, email],
  );

  const options = useMemo(() => {
    if (!domain) {
      return emailProviders;
    }

    return emailProviders?.filter(provider =>
      provider?.domain?.includes(domain),
    );
  }, [domain, emailProviders]);

  const handleChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      handleOpen: VoidFunction,
      open: boolean,
    ) => {
      const { value } = e.target;
      const emailHasOneOfProviders = emailProviders?.some(provider =>
        value.includes(provider?.domain),
      );

      if (
        emailHasOneOfProviders === open &&
        e.target == document?.activeElement
      ) {
        handleOpen();
      }

      setValue('email', value);
    },
    [setValue, emailProviders],
  );

  const loadMore = useCallback(fetchNextPage, [fetchNextPage]);

  const handleSetEmailProvider = useCallback(
    (e: EmailProviders) => {
      setValue('email', `${email?.split('@')?.[0]}@${e?.domain}`, {
        shouldDirty: true,
      });
    },
    [setValue, email],
  );

  return (
    <SelectInput
      options={options}
      getOptionLabel={e => e?.domain}
      getOptionValue={e => e?.id}
      value={undefined}
      isAsync
      hasNextPage={hasNextPage}
      loadMore={loadMore}
      loadingMore={isLoading || isFetchingNextPage}
      setValue={handleSetEmailProvider}
      error={error}
      refetch={refetch}
      customComponents={{
        customInput({ handleOpen, open }) {
          return (
            <Input
              type="text"
              value={email}
              placeholder={t('email_placeholder')}
              onChange={e => handleChange(e, handleOpen, open)}
              isError={!isValidEmail && touchedFields?.email}
              errorMessage={tCommon('invalid_email')}
              onFocus={() => {
                if (!open && !emailHasOneOfProvidersExternal) {
                  handleOpen();
                }
              }}
              icon={isValidEmail ? 'fa-solid fa-circle-check' : undefined}
              hasPaddingRight
            />
          );
        },
        customOption: ({ handleSelect, key, option, selected }) => (
          <SelectOptionWithHighlight
            key={key}
            handleSelect={handleSelect}
            option={option}
            selected={selected}
            label={(email ?? '')?.split('@')?.[0] + '@'}
            description={option?.domain}
          />
        ),
      }}
    />
  );
}
