import { useMemo } from 'react';
import styles from './style.module.scss';
import RollPointGameContentSlotCard from './Card';

export default function RollPointGameContentSlot() {
  const Cards = useMemo(
    () =>
      Array.from({ length: 3 }).map((_, index) => (
        <RollPointGameContentSlotCard
          key={`slot-column-${index}`}
          column={index}
        />
      )),
    [],
  );

  return (
    <div className={styles.container}>
      <div className={styles.container__content}>{Cards}</div>
    </div>
  );
}
