import { useRewards } from '@/api/rewards';
import { useUserRewards } from '@/api/user-rewards';
import { useMemo } from 'react';
import Reward from './Reward';
import { ErrorChip } from '@starsoft/common/components';
import { RewardsProps } from './props';

import styles from './styles.module.scss';
import { useUserAccount } from '@/api/user/queries';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';

export default function Rewards({ isSmall }: RewardsProps) {
  const { user } = useUserAccount();
  const isAuthorized = !!user;
  const { isV2LayoutVariant } = useLayoutSettings();

  const {
    rewards: userRewards,
    isLoading: isLoadingUserRewards,
    error: errorUserRewards,
    refetch: refetchUserRewards,
  } = useUserRewards();
  const {
    rewards,
    isLoading: isLoadingRewards,
    error: errorRewards,
    refetch: refetchRewards,
  } = useRewards({
    enabled: !isAuthorized,
  });
  const { dynamicRewards, error, isLoading, refetch } = isAuthorized
    ? {
        dynamicRewards: userRewards,
        isLoading: isLoadingUserRewards,
        error: errorUserRewards,
        refetch: refetchUserRewards,
      }
    : {
        dynamicRewards: rewards,
        isLoading: isLoadingRewards,
        error: errorRewards,
        refetch: refetchRewards,
      };

  const Rewards = useMemo(
    () =>
      dynamicRewards?.data?.map(reward => (
        <Reward
          key={`reward_item_${reward.id}`}
          reward={reward}
          isSmall={isSmall || isV2LayoutVariant}
        />
      )),
    [dynamicRewards, isSmall, isV2LayoutVariant],
  );

  const Skeletons = useMemo(
    () =>
      Array.from({ length: 6 }).map((_, i) => (
        <Reward key={`reward_skeleton_item_${i}`} isLoading isSmall={isSmall} />
      )),
    [isSmall],
  );

  if (error) {
    return (
      <div
        className={`${styles.error} ${isSmall ? styles['error--isSmall'] : ''}`}
      >
        <ErrorChip action={refetch} />
      </div>
    );
  }

  return (
    <section
      className={`${styles.container} ${isSmall ? styles['container--isSmall'] : ''} ${isV2LayoutVariant ? styles['container--variant'] : ''}`}
    >
      {isLoading ? Skeletons : Rewards}
    </section>
  );
}
