import { useUserAccount } from '@/api/user';
import { SocketIoFacade } from '@/services/SocketIoFacade';
import { Nullable } from '@package/interfaces';
import { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

interface UseSocketIoFactoryProps {
  gateway: string;
  isProtected?: boolean;
}

export function useSocketIoFactory({
  gateway,
  isProtected,
}: UseSocketIoFactoryProps): Nullable<Socket> {
  const [socket, setSocket] = useState<Nullable<Socket>>(null);
  const { isAuthorized } = useUserAccount();

  function createSocketConnection() {
    if ((isProtected && !isAuthorized) || typeof window === 'undefined') {
      return;
    }

    let _socket: Nullable<Socket> = null;

    _socket = SocketIoFacade.getSocketInstance(gateway);

    setSocket(_socket);

    return () => {
      _socket?.removeAllListeners();
      _socket?.close();
      setSocket(null);
    };
  }

  useEffect(createSocketConnection, [gateway, isProtected, isAuthorized]);

  return socket;
}
