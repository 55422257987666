import { DatePickerMobileSelectorColumnCellProps } from './props';

export function getDatePickerOptionLabel<T>(
  { type }: Pick<DatePickerMobileSelectorColumnCellProps<T>, 'type'>,
  value: T,
) {
  switch (type) {
    case 'day':
      return Number(value);
    case 'month':
      return `month_${value}`;
    case 'year':
      return value;
  }
}
