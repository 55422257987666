import { Nullable } from '@package/interfaces';

export type RollPointStateProps = {
  animate: boolean;
  result: Nullable<Array<number>>;
};

export enum RollPointActions {
  SetIsAnimating = 'roll-point/set-animating',
  SetResult = 'roll-point/set-result',
}

export interface RollPointBasePayload<T extends RollPointActions, V = null> {
  type: T;
  payload: V;
}

export type SetRollPointAnimation = RollPointBasePayload<
  RollPointActions.SetIsAnimating,
  boolean
>;
export type SetRollPointResult = RollPointBasePayload<
  RollPointActions.SetResult,
  Nullable<Array<number>>
>;

export type RollPointStatePayload = SetRollPointAnimation | SetRollPointResult;
