export enum ComponentTypeId {
  Login = 1,
  GameCard = 2,
  Toasty = 3,
  Notifications = 4,
  BetAmountInput = 5,
  Layout = 6,
  Tabs = 7,
  ProfileRankCards = 8,
  UserProfileChip = 9,
  TournamentCard = 10,
  VipClubCards = 11,
  MainBanners = 12,
}
