import { TransWithDefault } from '@package/components';
import styles from './styles.module.scss';
import RollPointGameContentSlot from './Slot';

export default function RollPointGameContent() {
  return (
    <div className={styles.container}>
      <h2 className={styles.container__title}>
        <TransWithDefault
          i18nKey="common:roll_point_game_heading"
          values={{
            level: 'Vip 1',
          }}
        />
      </h2>
      <p className={styles.container__description}>
        <TransWithDefault
          i18nKey="common:roll_point_game_description"
          values={{
            startTime: new Date(),
          }}
        />
      </p>
      <div className={styles.container__content}>
        <div className={styles.container__content__row}>
          <div className={styles.container__content__row__label}>
            <TransWithDefault
              i18nKey="common:expires_in"
              values={{
                countdown: '00:00:30',
              }}
            />
          </div>
          <div className={styles.container__content__row__label}>
            <TransWithDefault
              i18nKey="common:remaining_numbers"
              values={{
                count: '0',
              }}
            />
          </div>
        </div>
        <RollPointGameContentSlot />
        <div className={styles.container__divider} />
        <div className={styles.container__content__row}>
          <div className={styles.container__content__row__label}>
            <TransWithDefault
              i18nKey="common:todays_reward"
              values={{
                amount: 'R$ 2000,00',
              }}
            />
          </div>
        </div>
        <div>table</div>
      </div>
    </div>
  );
}
