import React from 'react';
import AuthModalContentLoginSteps from './Steps';
import LoginLayout from '../Layout';

export default function LoginModal({ id }: { id: number }) {
  return (
    <LoginLayout id={id}>
      <AuthModalContentLoginSteps />
    </LoginLayout>
  );
}
