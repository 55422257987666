import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import RankCardProgress from './Progress';
import { ProfileRankCardProps } from './props';
import styles from './styles.module.scss';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';
import { ComponentTypeId } from '@package/src/models/setting/layout/component/type/types.enum';
import { Image } from '@package/components';
import RankLabel from '@/components/RankLabel';
import ProfileRankCardSkeleton from './Skeleton';

export default function ProfileRankCard({
  player,
  onClick,
  isLoading,
  isRedirect,
  rankStatus,
  isTransparent,
}: ProfileRankCardProps): JSX.Element {
  const { components } = useResolvedTheme();
  const version: SettingLayoutComponentId =
    components.get(ComponentTypeId.ProfileRankCards) ??
    SettingLayoutComponentId.ProfileRankCardV1;

  if (isLoading) {
    return (
      <ProfileRankCardSkeleton
        isRedirect={isRedirect}
        isTransparent={isTransparent}
      />
    );
  }

  return (
    <div
      className={`${styles.container} ${isTransparent ? styles['container--transparent'] : ''}`}
      onClick={onClick}
    >
      <div className={styles.container__row}>
        <div className={styles.container__profile}>
          {version === SettingLayoutComponentId.ProfileRankCardV1 && (
            <RankCardProgress rankStatus={rankStatus} />
          )}

          <Image
            alt={`user-avatar-${player?.id}`}
            src={player?.photo}
            width={80}
            height={80}
            className={styles.container__profile__image}
          />
        </div>

        <div className={styles.container__column}>
          <h6 className={styles.container__title}>{player?.username}</h6>

          <RankLabel rank={rankStatus?.rank} />

          {version === SettingLayoutComponentId.ProfileRankCardV2 && (
            <div
              className={`${styles.container__row} ${styles['container__row--small']}`}
            >
              <RankCardProgress rankStatus={rankStatus} />
              <span className={styles.container__title}>
                {rankStatus?.progress}%
              </span>
            </div>
          )}
        </div>
      </div>

      {isRedirect && (
        <i
          className={`fa-solid fa-chevron-right ${styles['container__chevron']}`}
        />
      )}
    </div>
  );
}
