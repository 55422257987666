import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { Tabs } from '@package/components';
import { TabsProps } from '@package/src/components/Tabs/props';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';
import { ComponentTypeId } from '@package/src/models/setting/layout/component/type/types.enum';

export function TabsWithVersion<T>({
  ...props
}: Omit<TabsProps<T>, 'version'>) {
  const { components } = useResolvedTheme();
  const version: SettingLayoutComponentId = components.get(
    ComponentTypeId.Tabs,
  );

  return <Tabs version={version} {...props} />;
}
