import { Reward } from '@/models/userReward/reward';
import { UserRewardStatus } from '@/models/userReward/user-reward-status.enum';
import { ModalsKey } from '@/enums/modalsKey';
import { RewardId } from '@/enums/reward-id';
import { useUserAccount } from '@/api/user/queries';
import { useCallback } from 'react';
import { useModalNavigation } from './modals/useModalNavigation';
import { useCountdown } from '@starsoft/common/src/hooks/useCountdown';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { UserRewardsQueryKeys } from '@/api/user-rewards/queryKeys';

export function useRewardState(reward?: Reward) {
  const { user } = useUserAccount();
  const queryClient: QueryClient = useQueryClient();
  const isLuckyWheel = reward?.id === RewardId.LuckyWheel;
  const isDisabled = !reward?.userReward && !isLuckyWheel;
  const { getModalLink } = useModalNavigation();
  const isCountdownDisabled: boolean =
    !reward?.userReward ||
    (!!reward?.userReward &&
      ![UserRewardStatus.Claimable, UserRewardStatus.Pending].includes(
        reward?.userReward?.status,
      ));

  const isCountdownVisible = [
    UserRewardStatus.Pending,
    UserRewardStatus.Claimable,
  ].includes(reward?.userReward?.status);

  const { hours, minutes, seconds, days } = useCountdown({
    targetDate:
      reward?.userReward?.status == UserRewardStatus.Claimable
        ? reward?.userReward?.expireAt
        : (reward?.userReward?.redeemableAt as string),
    disabled: isCountdownDisabled,
    onCountdownComplete: () => {
      if (isCountdownDisabled) {
        return;
      }

      queryClient.invalidateQueries({
        queryKey: [UserRewardsQueryKeys.Primary],
      });
    },
  });

  const getLink = useCallback(() => {
    if (!user) {
      return getModalLink(ModalsKey.Login);
    }

    switch (reward?.id) {
      case RewardId.LuckyWheel:
        return getModalLink(
          ModalsKey.LuckySpin,
          `?reward-id=${reward?.id}&mode=1`,
        );
      default:
        return getModalLink(ModalsKey.ClaimReward, `?reward-id=${reward?.id}`);
    }
  }, [getModalLink, user, reward]);

  return {
    isDisabled,
    timer: {
      days,
      hours,
      minutes,
      seconds,
    },
    getLink,
    isCountdownVisible,
  };
}
