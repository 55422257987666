import tabItems from './items';
import { ItemProps, TabProps } from './props';
import useTranslation from 'next-translate/useTranslation';
import { usePathname } from '@/hooks/usePathname';
import styles from './styles.module.scss';
import Link from '../core/Link';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Nullable } from '@package/interfaces';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import SidebarHeaderContentTabs from '../SideBar/Header/Content/Tabs';

function Tab({ isSecondary }: TabProps) {
  const pathname: string = usePathname();
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);
  const indicatorRef = useRef<Nullable<HTMLDivElement>>(null);
  const { t } = useTranslation('common');
  const { isV2LayoutVariant } = useLayoutSettings();

  const position: number = useMemo(
    () =>
      tabItems.findIndex(
        tab =>
          pathname.includes(tab.path) ||
          tab.activePaths.some(path => pathname.includes(path)),
      ),
    [pathname],
  );

  const handleAdjustIndicator = useCallback(() => {
    if (!indicatorRef.current || !containerRef.current) return;

    const tabsContainer = containerRef.current;
    const indicatorEl = indicatorRef.current;
    const tabButtons = tabsContainer.querySelectorAll('a');

    if (!tabButtons[position]) {
      indicatorEl.style.width = '0px';
      return;
    }

    const tabEl = tabButtons[position];

    const left = tabEl.offsetLeft;
    const width = tabEl.offsetWidth;

    indicatorEl.style.width = `${width}px`;
    indicatorEl.style.transform = `translateX(${left}px)`;
  }, [position, containerRef]);

  function onMount() {
    handleAdjustIndicator();

    window.addEventListener('resize', handleAdjustIndicator);
    return () => {
      window.removeEventListener('resize', handleAdjustIndicator);
    };
  }

  useEffect(onMount, [handleAdjustIndicator]);

  if (isV2LayoutVariant) {
    return <SidebarHeaderContentTabs />;
  }

  return (
    <div
      ref={containerRef}
      className={`${styles.container} ${isSecondary ? styles['container--secondary'] : ''}`}
    >
      {tabItems.map((item: ItemProps) => {
        return (
          <Link
            key={`tab_item_${item.path}`}
            className={`${styles.container__tab} ${
              pathname.includes(item.path) ||
              item.activePaths.some(path => pathname.includes(path))
                ? styles['container__tab--active']
                : ''
            }`}
            href={item.path}
          >
            <i className={item.icon} style={{ color: item?.iconColor }} />
            {t(item.name)}
          </Link>
        );
      })}

      <div className={styles.container__indicator} ref={indicatorRef} />
    </div>
  );
}

export default Tab;
