import { useFormContext } from 'react-hook-form';
import { RegisterFormProps } from '@/types/register/props';
import { FormGroup, PasswordInput } from '@package/components';
import useTranslation from 'next-translate/useTranslation';

export default function AuthModalRegisterPasswordContent(): JSX.Element {
  const {
    watch,
    register,
    formState: { touchedFields, dirtyFields },
  } = useFormContext<RegisterFormProps>();
  const password = watch('password');

  const { t } = useTranslation('register');

  return (
    <FormGroup label={t('password_placeholder')}>
      <PasswordInput
        register={register}
        name="password"
        isTouched={touchedFields?.password}
        isDirty={dirtyFields?.password}
        password={password}
      />
    </FormGroup>
  );
}
