import { AudioService } from '@/services/AudioService';
import {
  NotificationsActions,
  NotificationsPayload,
  NotificationsStateProps,
} from './props';
import { NotificationTypeId } from '@/enums/notificationTypeId';
import { NotificationGroupId } from '@/enums/notificationGroupId';

const initialState: NotificationsStateProps = {
  tab: NotificationGroupId.Inbox,
  isSocketConnected: false,
  notificationsToasty: [],
};

export default function reducer(
  state: NotificationsStateProps = initialState,
  action: NotificationsPayload,
): NotificationsStateProps {
  switch (action.type) {
    case NotificationsActions.AddNotification:
      const isGameBet: boolean =
        (action.payload.type.id as number) == NotificationTypeId.GameBet;
      const notifySound: string = isGameBet
        ? '/audios/enter-bet.wav'
        : '/audios/notification.mp3';

      AudioService.play(notifySound, 0.3);

      return {
        ...state,
        notificationsToasty: state.notificationsToasty.concat(action.payload),
      };
    case NotificationsActions.RemoveNotification:
      return {
        ...state,
        notificationsToasty: state.notificationsToasty.filter(
          notification => notification.id !== action.payload,
        ),
      };

    case NotificationsActions.SetTab:
      return {
        ...state,
        tab: action.payload,
      };
    case NotificationsActions.SetSocketConnection:
      return {
        ...state,
        isSocketConnected: action.payload,
      };
    default:
      return state;
  }
}
