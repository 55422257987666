import LogoutButton from '@/components/ProfileDropdown/LogoutButton';
import ProfileDrawerContentHeader from './Header';
import styles from './styles.module.scss';
import ProfileDrawerContentBalance from './Balance';
import ProfileDrawerContentNavigation from './Navigation/Common';
import ProfileDrawerContentReferAndEarn from './ReferAndEarn';
import { StaggerAnimation } from '@package/components';
import ProfileDrawerContentNavigationSettings from './Navigation/Settings';
import SupportSection from '@/components/SideBar/Content/NotCollapsed/SupportSection';

export default function ProfileDrawerContent() {
  return (
    <div className={styles.container}>
      <ProfileDrawerContentHeader />
      <StaggerAnimation
        direction="column"
        stagger={0.15}
        staggerDirection="up"
        className={styles.container__content}
      >
        <ProfileDrawerContentBalance />
        <ProfileDrawerContentNavigation />
        <ProfileDrawerContentReferAndEarn />
        <ProfileDrawerContentNavigationSettings />
        <SupportSection />
        <LogoutButton />
      </StaggerAnimation>
    </div>
  );
}
