import { useMemo } from 'react';
import { RollPointGameContentSlotCardItemProps } from './props';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@/lib/store';
import { useRollPointColumnsGeneration } from '@/hooks/roll-point/useRollPointColumnsGeneration';

export default function RollPointGameContentSlotCardItem({
  column,
}: RollPointGameContentSlotCardItemProps) {
  const animating: boolean = useSelector(
    (state: RootState) => state.rollPoint.animate,
  );

  const { columns, getLabel, resultPosition } = useRollPointColumnsGeneration({
    column,
  });

  const Slots = useMemo(
    () =>
      Array.from({ length: columns }).map((_, index) => (
        <div
          className={styles.wrapper__item}
          key={`slot-item-column-${column}-position-${index}`}
        >
          {getLabel(index)}
        </div>
      )),
    [column, columns, getLabel],
  );

  return (
    <div
      style={{
        //@ts-ignore
        '--result-position': resultPosition,
        '--animation-delay': `${(column + 1) * 0.4}s`,
      }}
      className={`${styles.wrapper} ${animating ? styles['wrapper--animating'] : ''}`}
    >
      {Slots}
    </div>
  );
}
