import {
  setIsAnimatingRollPointGame,
  setRollPointResult,
} from '@/lib/store/roll-point/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function useRollPointSpinButton() {
  const dispatch = useDispatch();

  const generateRandomNumbers = useCallback(() => {
    const randomNumber = Math.floor(Math.random() * 1000);
    const randomNumberString = randomNumber.toString().padStart(3, '0');
    return randomNumberString.split('').map(Number);
  }, []);

  const handleSpinAndGenerateNumbers = useCallback(() => {
    dispatch(setIsAnimatingRollPointGame(true));
    const numbers = generateRandomNumbers();
    dispatch(setRollPointResult(numbers));
  }, [dispatch, generateRandomNumbers]);

  const spin = useCallback(() => {
    handleSpinAndGenerateNumbers();
  }, [handleSpinAndGenerateNumbers]);

  return { spin };
}
