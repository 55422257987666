import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';

import dynamic from 'next/dynamic';

const SidebarContentV1 = dynamic(() => import('./V1'), { ssr: true });
const SidebarContentV2 = dynamic(() => import('./V2'), { ssr: true });

export default function SidebarContentRenderer() {
  const { layoutVariant } = useLayoutSettings();

  switch (layoutVariant) {
    case SettingLayoutComponentId.LayoutV1:
    case SettingLayoutComponentId.LayoutV3:
      return <SidebarContentV1 />;
    case SettingLayoutComponentId.LayoutV2:
      return <SidebarContentV2 />;
    default:
      return <SidebarContentV1 />;
  }
}
