import { useUserAccount } from '@/api/user';
import { ErrorChip } from '@package/components';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';
import React from 'react';
import { useRankStatusByUser } from '@/api/rank-status';
import ProfileRankCard from '@/components/core/ProfileRankCard';

export default function ProfileDrawerContentHeader() {
  const { navigateToModal } = useModalNavigation();

  const {
    rankStatus,
    isLoading: loadingRankStatus,
    error: errorRankStatus,
    refetch: refetchRankStatus,
  } = useRankStatusByUser();
  const {
    isLoading: loadingUserAccount,
    error: errorUserAccount,
    refetch: refetchUserAccount,
    user,
  } = useUserAccount();

  const isLoading = loadingUserAccount || loadingRankStatus;
  const error = errorRankStatus || errorUserAccount;

  function handleError() {
    if (errorUserAccount) {
      refetchUserAccount();
    }

    if (errorRankStatus) {
      refetchRankStatus();
    }
  }

  function handleClick() {
    navigateToModal(ModalsKey.UserProfile, `?userSlug=${user?.player?.slug}`);
  }

  if (error) {
    return <ErrorChip action={handleError} />;
  }

  return (
    <ProfileRankCard
      isRedirect
      isTransparent
      isLoading={isLoading}
      onClick={handleClick}
      player={user?.player}
      rankStatus={rankStatus}
    />
  );
}
