import { ModalsKey } from '@/enums/modalsKey';
import { PageProps } from '@/types/page/props';

export default <PageProps[]>[
  {
    label: 'tab_wallet',
    paths: ['/account/wallet'],
    icon: 'fa-solid fa-wallet',
    isProtected: true,
  },
  {
    label: 'vip_club_title',
    paths: ['/vip-club'],
    icon: `fa-solid fa-crown`,
    isProtected: true,
  },
  {
    label: 'tab_affiliates',
    paths: ['/affiliates/panel'],
    icon: `fa-solid fa-gift fa-swap-opacity`,
    isProtected: false,
  },
  {
    label: 'tab_my_bets',
    paths: ['/account/game-history'],
    icon: 'fa-solid fa-ticket',
    isProtected: true,
  },
  {
    label: 'tab_profile',
    paths: [],
    modal: ModalsKey.UserProfile,
    icon: `fa-solid fa-user`,
    isProtected: true,
  },
  {
    label: 'tab_notification',
    paths: [],
    modal: ModalsKey.Notifications,
    icon: 'fa-solid fa-envelope fa-swap-opacity',
    isProtected: true,
  },
  {
    label: 'tab_settings',
    paths: [
      '/account',
      '/account/deposit-history',
      '/account/withdraw-history',
      '/account/game-history',
    ],
    icon: `fa-solid fa-gear`,
    isProtected: true,
  },
];
