import { GenericError } from '@/models/generic-error';
import { AxiosError } from 'axios';
import { NotificationsService } from '../NotificationsService';

export class ErrorService {
  static extractError(error: AxiosError<GenericError>): string {
    if (!!error?.response?.data?.errors) {
      return error.response.data.errors[0];
    }

    return Array.isArray(error?.response?.data?.message)
      ? error?.response?.data?.message[0]
      : error?.response?.data?.message;
  }

  static extractServerError(error: AxiosError<GenericError>): GenericError {
    return error?.response?.data as GenericError;
  }

  static onError(error: AxiosError<GenericError>, title?: string) {
    const errorMessage: string = ErrorService.extractError(error);

    NotificationsService.error({
      title: title ? title : `error_title`,
      message: errorMessage,
    });
  }
}
