import { Nullable } from '@package/interfaces';

export enum WalletActions {
  Collapse = 'wallet/collapse',
  SetHiddenValue = 'wallet/setHiddenValue',
  ToggleConvertToCoin = 'wallet/toggleConvertToCoin',
  SetConversionCoin = 'wallet/setFiatConversionCoin',
  SetIsUpdating = 'wallet/setIsUpdating',
}

export type WalletStateProps = {
  collapsed: boolean;
  hiddenSmalls: boolean;
  convertToCoin: boolean;
  conversionCoinId: Nullable<number>;
  isUpdating: boolean;
};
export interface WalletPayload<T extends WalletActions, V = null> {
  type: T;
  payload: V;
}

export type WalletCollapsedPayload = WalletPayload<
  WalletActions.Collapse,
  boolean
>;

export type WalletSetConversionCoin = WalletPayload<
  WalletActions.SetConversionCoin,
  number
>;

export type WalletSetIsUpdatingPayload = WalletPayload<
  WalletActions.SetIsUpdating,
  boolean
>;

export type WalletStatePayload =
  | WalletPayload<WalletActions.Collapse>
  | WalletPayload<WalletActions.SetHiddenValue>
  | WalletPayload<WalletActions.ToggleConvertToCoin>
  | WalletPayload<WalletActions.SetHiddenValue>
  | WalletSetConversionCoin
  | WalletSetIsUpdatingPayload;
