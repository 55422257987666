import { memo } from 'react';
import { SidebarNotCollapsedItemSectionProps } from './props';
import styles from './styles.module.scss';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import { getComputedColor } from '@package/utils';
import Color from 'color';

function SidebarNotCollapsedItemSection({
  icon,
  label,
  onClick,
  isOpen,
  shouldShowChevron,
  color,
  isHighligh,
}: SidebarNotCollapsedItemSectionProps) {
  const { isCollapsed, layoutVariant } = useLayoutSettings();
  const isV2LayoutVariant: boolean =
    layoutVariant == SettingLayoutComponentId.LayoutV2;

  return (
    <div
      className={`${styles.container} ${isOpen ? styles['container--open'] : ''} ${isHighligh ? styles['container--highlight'] : ''} ${isV2LayoutVariant && isCollapsed ? styles['container--v2'] : ''} ${layoutVariant == SettingLayoutComponentId.LayoutV3 ? styles['container--v3'] : ''}`}
      onClick={onClick}
      style={{
        //@ts-ignore
        '--primary-alpha':
          layoutVariant == SettingLayoutComponentId.LayoutV3
            ? Color(getComputedColor('--primary-color')).alpha(0.1).toString()
            : undefined,
      }}
    >
      <div className={styles.container__row}>
        <i className={`${styles.container__icon} ${icon}`} style={{ color }} />
        {isV2LayoutVariant ? (!isCollapsed ? label : null) : label}
      </div>

      {shouldShowChevron && !isCollapsed && (
        <button
          className={`${styles.container__chevron} ${isOpen ? styles['container__chevron--open'] : ''}`}
        >
          <i className="fas fa-chevron-right" />
        </button>
      )}
    </div>
  );
}

export default memo(SidebarNotCollapsedItemSection);
