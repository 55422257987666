import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';
import { ComponentTypeId } from '@package/src/models/setting/layout/component/type/types.enum';
import { RootState } from '@/lib/store';
import { useSelector } from 'react-redux';
import { useIsMobile } from '@package/hooks';
import { useCompomentVariant } from '../useComponentVariant';

export default function useLayoutSettings() {
  const isMobile = useIsMobile();
  const isCollapsed: boolean = useSelector(
    (state: RootState) => state.sideBar.collapsed,
  );
  const layoutVariant =
    useCompomentVariant(ComponentTypeId.Layout) ??
    SettingLayoutComponentId.LayoutV1;
  const isV2LayoutVariant = layoutVariant == SettingLayoutComponentId.LayoutV2;

  return {
    layoutVariant,
    isV2LayoutVariant,
    isCollapsed: isMobile ? false : isCollapsed,
  };
}
