import { ModalsKey } from '@/enums/modalsKey';
import { Button, ModalLayout } from '@package/components';
import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { PropsWithChildren } from 'react';
import { useRollPointSpinButton } from '@/hooks/roll-point/useRollPointSpinButton';
import { useSelector } from 'react-redux';
import { RootState } from '@/lib/store';

export default function RollPointGameLayout({ children }: PropsWithChildren) {
  const { t } = useTranslation('common');
  const isSpinning = useSelector((state: RootState) => state.rollPoint.animate);
  const { spin } = useRollPointSpinButton();
  const result = useSelector((state: RootState) => state.rollPoint.result);

  return (
    <ModalLayout modal={ModalsKey.RollPointGame} title={t('roll_point_title')}>
      <div className={styles.container}>
        {result}
        <div className={styles.container__content}>{children}</div>
        <Button type="button" onClick={spin} disabled={isSpinning}>
          {t('roll')}
        </Button>
      </div>
    </ModalLayout>
  );
}
