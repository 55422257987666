import { RootState } from '@/lib/store';
import { setSettings } from '@/lib/store/theme/actions';
import { Setting } from '@package/models';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';
import { ComponentTypeId } from '@package/src/models/setting/layout/component/type/types.enum';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useResolvedTheme(setting?: Setting) {
  const dispatch = useDispatch();
  const stateSetting = useSelector((state: RootState) => state.theme.setting);

  const components: Map<ComponentTypeId, SettingLayoutComponentId> =
    useMemo(() => {
      const settings = setting || stateSetting;
      return new Map<ComponentTypeId, SettingLayoutComponentId>(
        settings?.components?.map(component => [
          component?.componentType?.id,
          component?.component?.id as SettingLayoutComponentId,
        ]),
      );
    }, [stateSetting, setting]);

  const handleInitStateSetting = useCallback(() => {
    if (!setting) {
      return;
    }

    dispatch(setSettings(setting));
  }, [setting, dispatch]);

  useEffect(handleInitStateSetting, [handleInitStateSetting]);

  return {
    setting: setting ?? stateSetting,
    components,
  };
}
