import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';
import dynamic from 'next/dynamic';

const LayoutV1 = dynamic(() => import('./v1'), { ssr: true });
const LayoutV2 = dynamic(() => import('./v2'), { ssr: true });
const LayoutV3 = dynamic(() => import('./v3'), { ssr: true });
const LayoutV4 = dynamic(() => import('./v4'), { ssr: true });

export function UserRankCard({ id }: { id: SettingLayoutComponentId }) {
  switch (id) {
    case SettingLayoutComponentId.UserProfileChipV1:
    default:
      return <LayoutV1 />;
    case SettingLayoutComponentId.UserProfileChipV2:
      return <LayoutV2 />;
    case SettingLayoutComponentId.UserProfileChipV3:
      return <LayoutV3 />;
    case SettingLayoutComponentId.UserProfileChipV4:
      return <LayoutV4 />;
  }
}
