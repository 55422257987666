export enum ModalsKey {
  Deposit = 'deposit',
  EditFollowBet = 'edit-follow-bet',
  FollowBet = 'follow-bet',
  GameReport = 'game-report',
  ProfileUrl = 'profile-url',
  CancelRegister = 'cancel_register',
  UserProfile = 'user-profile',
  Withdraw = 'withdraw',
  RolloverRequired = 'rollover-required',
  LiveChatRules = 'live-chat-rules',
  Terms = 'terms-refer',
  Login = 'login',
  Register = 'register',
  FollowBetTutorial = 'follow-tutorial',
  TrendTutorialModal = 'trenball-trend-tutorial',
  LuckySpin = 'lucky-spin',
  VipLevel = 'vip-level',
  ClaimReward = 'claim-reward',
  SupportModal = 'support-modal',
  LastRounds = 'last-rounds',
  LastBets = 'last-bets',
  Story = 'story',
  CreateReferralVoucher = 'create-referral-voucher',
  DeleteReferralVoucher = 'delete-referral-voucher',
  EditReferralVoucher = 'edit-referral-voucher',
  WithdrawReferralComissions = 'referral-withdraw-comissions',
  UnavailableBalance = 'unavailable-balance',
  Wallet = 'wallet',
  RecoverPassword = 'recover-password',
  RecoverPasswordReset = 'recover-password-reset',
  CoinConversion = 'coin-conversion',
  AuthComponentModal = 'auth-component-modal',
  UserPhoneNumber = 'user-phone-number',
  UserEmail = 'user-email',
  Language = 'language',
  VerifyKyc = 'verify-kyc',
  MissionUncrossableDifficultyInfo = 'mission-uncrossable-difficulty-info',
  BonusDetails = 'bonus-details',
  ProfileDetails = 'profile-details',
  RollPointGame = 'roll-point',
  Notifications = 'notifications',
  Share = 'share',
  TestUser = 'test-user',
}
