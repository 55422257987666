import AuthModalRegisterEmailContent from '../Email';
import AuthModalRegisterPasswordContent from '../Password';

export default function AuthModalContentRegisterSteps() {
  return (
    <>
      <AuthModalRegisterEmailContent />
      <AuthModalRegisterPasswordContent />
    </>
  );
}
