import { FormProvider, useForm } from 'react-hook-form';
import { RegisterFormProps } from '@/types/register/props';
import { NotificationsService } from '@/services/NotificationsService';
import { AuthService } from '@/services/AuthService';
import { ModalsKey } from '@/enums/modalsKey';
import { useReferralVoucherByCode } from '@/api/referral-vouchers/queries/useReferralVoucherByCode';
import Link from '@/components/core/Link';
import useTranslation from 'next-translate/useTranslation';
import { useRegister } from '@/api/iam';
import styles from './styles.module.scss';
import { useDebounce } from '@starsoft/common/hooks';
import {
  Animation,
  Navigate,
  TransWithDefault,
} from '@starsoft/common/components';
import { Button } from '@starsoft/common/components';
import useAuthSearchParams from '@/hooks/auth/useAuthSearchParams';
import { AuthStep } from '@/enums/auth/authStep';
import { PropsWithChildren } from 'react';
import OAuthRow from '../../OAuth';
import ReferralVoucherInput from '../Content/ReferralVoucherInput';
import useRegisterVoucher from '@/hooks/register/useRegisterVoucher';
import useAuthForm from '@/hooks/auth/useAuthForm';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { SettingLayoutComponentId } from '@package/src/models/setting/layout/component';

export default function RegisterLayout({
  children,
  id = 1,
}: PropsWithChildren<{ id: number }>): JSX.Element {
  const isVariant = id === SettingLayoutComponentId.LoginV3;
  const { t } = useTranslation('register');

  const { mutate, isLoading } = useRegister();
  const formMethods = useForm<RegisterFormProps>();
  const { step } = useAuthSearchParams();
  const { getModalLink } = useModalNavigation();

  const { handleSubmit, watch, setValue } = formMethods;

  const { voucher } = useRegisterVoucher({ setValue });
  const { isInvalidForm, email, isPasswordStep } = useAuthForm({ watch });

  const formVoucher = watch('voucher');
  const debouncedVoucher = useDebounce<string>(formVoucher ?? '', 500);

  const {
    isLoading: isLoadingVoucherCode,
    isError: isErrorVoucherCode,
    voucher: voucherData,
  } = useReferralVoucherByCode({
    refetchOnMount: 'always',
    voucher: debouncedVoucher,
    enabled: !!debouncedVoucher && debouncedVoucher?.length >= 21,
  });

  const onSubmit = (data: RegisterFormProps) => {
    const { password, email, voucher } = data;

    // switch (step) {
    //   case AuthStep.Email:
    //     handleSetAuthSearchParams('step', AuthStep.Password);
    //     break;
    //   case AuthStep.Password:
    //     const invalid: boolean =
    //       email.trim().length == 0 || password.trim().length == 0;

    //     if (invalid) {
    //       NotificationsService.error({
    //         title: 'form_error_title',
    //         message: 'form_error_description',
    //       });
    //       return;
    //     }

    //     mutate({
    //       ...data,
    //       voucher: isErrorVoucherCode ? null : voucher,
    //     });
    //     break;
    // }
    const invalid: boolean =
      email.trim().length == 0 || password.trim().length == 0;

    if (invalid) {
      NotificationsService.error({
        title: 'form_error_title',
        message: 'form_error_description',
      });
      return;
    }

    mutate({
      ...data,
      voucher: isErrorVoucherCode ? null : voucher,
    });
  };

  const onError = () => {
    NotificationsService.error({
      title: 'form_error_title',
      message: 'form_error_description',
    });
  };

  if (step == AuthStep.Password && !email) {
    return (
      <Navigate
        to={getModalLink(ModalsKey.Register, `?step=${AuthStep.Email}`)}
      />
    );
  }

  return (
    <Animation
      type="fadeInLeft"
      key={step}
      className={`${styles.container} ${isVariant ? styles['container--variant'] : ''}`}
    >
      {formVoucher && voucherData && !isPasswordStep && (
        <span className={styles.container__label}>
          {t('sponsor_label')}{' '}
          <strong>{voucherData?.user?.player?.username}</strong>
        </span>
      )}
      {isPasswordStep && <p className={styles.container__email}>{email}</p>}
      <FormProvider {...formMethods}>
        <form
          className={styles.container__form}
          onSubmit={handleSubmit(onSubmit, onError)}
          id="registerForm"
        >
          {children}
          {!isPasswordStep && (
            <>
              <ReferralVoucherInput
                isError={isErrorVoucherCode}
                isLoading={isLoadingVoucherCode}
                hasDefaultVoucher={!!voucher}
                voucherData={voucherData}
              />

              <div className={styles.container__terms}>
                <i className="fa-solid fa-circle-info" />

                <TransWithDefault
                  i18nKey="register:terms_label"
                  components={[
                    <p key={0} />,
                    <Link
                      key={1}
                      target="_blank"
                      href="/term/terms-of-service"
                    />,
                  ]}
                />
              </div>
            </>
          )}

          <Button
            type="submit"
            form="registerForm"
            isloading={isLoading || AuthService.isAuthorized()}
            disabled={
              (isErrorVoucherCode && !!formVoucher) ||
              isLoadingVoucherCode ||
              isInvalidForm
            }
          >
            {t('register_label')}
          </Button>
        </form>
      </FormProvider>
      {!isPasswordStep && (
        <>
          <span className={styles.container__binding}>
            {t('register_label_binding')}
          </span>
          <OAuthRow direction={isVariant ? 'row' : 'column'} />
          {!isVariant && (
            <span className={styles.container__text}>
              {t('login_text')}
              <Link
                modal={ModalsKey.Login}
                className={styles.container__text__highlight}
              >
                {t('login_label')}
              </Link>
            </span>
          )}
        </>
      )}
    </Animation>
  );
}
