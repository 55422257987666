import styles from './styles.module.scss';
import { RollPointGameContentSlotCardProps } from './props';
import RollPointGameContentSlotCardItem from './Item';

export default function RollPointGameContentSlotCard({
  column,
}: RollPointGameContentSlotCardProps) {
  return (
    <div className={styles.card}>
      <RollPointGameContentSlotCardItem column={column} />
    </div>
  );
}
