import LoginEmailContent from './Email';
import LoginPasswordContent from './Password';

export default function AuthModalContentLoginSteps() {
  // switch (step) {
  //   case AuthStep.Email:
  //     return <LoginEmailContent />;
  //   case AuthStep.Password:
  //     return <LoginPasswordContent />;
  // }

  return (
    <>
      <LoginEmailContent />
      <LoginPasswordContent />
    </>
  );
}
