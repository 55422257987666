import { AuthService } from '@/services/AuthService';
import { UseGoogleOneTapOptions } from './props';
import { useExternalScript, usePathname } from '@package/hooks';
import { Nullable } from '@package/interfaces';
import { CookiesKey } from '@/enums/cookiesKey';
import { setCookie } from 'cookies-next';
import { useEffect } from 'react';

export function useGoogleOneTap({
  clientId,
  context = 'use',
  loginUri,
  itpSupport = true,
}: UseGoogleOneTapOptions) {
  const isAuthorized: boolean = AuthService.isAuthorized();
  const pathname: string = usePathname();

  function handleLogin(response: google.accounts.id.CredentialResponse) {
    if (!loginUri) {
      return;
    }

    const voucher: Nullable<string> = AuthService.getVoucher();
    setCookie(CookiesKey.ClientRouter, pathname);

    window.location.href = `${loginUri}?code=${response.credential}${voucher ? `&voucher=${voucher}` : ''}`;
  }

  function onLoad() {
    if (pathname.includes('region-unavailable') || AuthService.isAuthorized()) {
      return;
    }

    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: handleLogin,
      context,
      itp_support: itpSupport,
      cancel_on_tap_outside: true,
    });

    window.google.accounts.id.prompt();
  }

  const { retry } = useExternalScript({
    src: 'https://accounts.google.com/gsi/client',
    callback: onLoad,
  });

  function onIsAuthorized() {
    if (isAuthorized) {
      return;
    }

    retry();
  }

  useEffect(onIsAuthorized, [isAuthorized, retry]);
}
